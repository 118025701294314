import { DependencyList, EffectCallback, useRef } from "react"
import { useEffect } from "react"

const useEffectUnsafe = (effect: EffectCallback, deps: DependencyList) => {
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            effect();
        }
        // eslint-disable-next-line
    }, deps);
}

export default useEffectUnsafe;