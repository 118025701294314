import "./SelectGroupDialog.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { CountryModel, ExchangeGroupModel, ItemModel, LanguageModel, SelectItemModel, UserMembershipDataModel, UserMembershipModel } from "../models/Models";
import { ApiService } from "../services/ApiService";
import useEffectUnsafe from "../extensions/UseEffectUnsafe";
import { Utils } from "../extensions/Utils";
import MainButton from "../components/MainButton";
import BackButton from "../components/BackButton";

interface IProps {
    initData: string;
}

const maxLeasingAmounts = Array.from(new Array(9), (_, i) => {
    return {
        value: i + 1,
        label: (i + 1).toString(),
        data: i + 1
    } as SelectItemModel<number>;
});

const SelectGroupDialog = (props: IProps) => {
    const [step, setStep] = useState<"select" | "fail">("select");
    const [selectedCountry, setSelectedCountry] = useState<SelectItemModel<CountryModel>>();
    const [countries, setCountries] = useState<SelectItemModel<CountryModel>[]>([]);
    const [selectedCity, setSelectedCity] = useState<SelectItemModel<ItemModel>>();
    const [cities, setCities] = useState<SelectItemModel<ItemModel>[]>([]);
    const [languages, setLanguages] = useState<SelectItemModel<LanguageModel>[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<SelectItemModel<LanguageModel>>();
    const [exchangeGroups, setExchangeGroups] = useState<SelectItemModel<ExchangeGroupModel>[]>([]);
    const [selectedExchangeGroup, setSelectedExchangeGroup] = useState<SelectItemModel<ExchangeGroupModel>>();
    const [selectedMaxLeasingAmount, setSelectedMaxLeasingAmount] = useState<SelectItemModel<number>>(maxLeasingAmounts[0]);
    const [userMembership, setUserMembership] = useState<UserMembershipDataModel | null>(null);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [addCityUrl, setAddCityUrl] = useState<string>("");
    const useMembership = useRef(true);

    const { t } = useTranslation();

    const loadLanguages = useCallback(() => {
        ApiService.loadLanguages(props.initData)
            .then(languages => {
                const items = Utils.toSelectItems(languages);

                setLanguages(items);

                if (items.length > 0) {
                    const code = Telegram.WebApp.initDataUnsafe.user?.language_code;
                    const language = (code ? items.find(x => x.data?.code === code) : undefined) ?? items[0];
                    setSelectedLanguage(language);
                } else {
                    setSelectedLanguage(undefined);
                }
            },
            () => {});
    }, [props.initData]);

    const loadCountries = useCallback(() => {
        let countriesData = new Array<SelectItemModel<CountryModel>>();

        ApiService.loadCountries(props.initData)
            .then(countries => {
                countriesData = Utils.toSelectItems(countries);
                return ApiService.getUserMembership(props.initData);
            },
            () => {})
            .then(userMembership => {
                const membership = userMembership as UserMembershipModel;
                const membershipData = membership.data as UserMembershipDataModel | null;

                useMembership.current = membershipData !== null;

                setAddCityUrl(membership.addCityUrl);
                setUserMembership(membership.data);
                setCountries(countriesData);

                let country = membershipData !== null ?
                    countriesData.find(x => x.data?.id === membershipData.countryId)
                    : undefined;

                if (!country && countriesData.length > 0) {
                    country = countriesData[0];
                }

                setSelectedCountry(country);
            },
            () => {});
    }, [props.initData]);

    const loadCities = useCallback(() => {
        if (!selectedCountry) {
            setCities([]);
            return;
        }

        ApiService.loadCities(props.initData, selectedCountry.value)
            .then(cities => {
                const items = Utils.toSelectItems(cities);

                setCities(items);

                let city = useMembership.current && userMembership !== null
                    ? items.find(x => x.data?.id === userMembership.cityId)
                    : undefined;

                if (!city && items.length > 0) {
                    city = items[0];
                }

                setSelectedCity(city);
            },
            () => {});
    }, [props.initData, userMembership, selectedCountry]);

    const fillExchangeGroups = useCallback((items: SelectItemModel<ExchangeGroupModel>[]) => {
        setExchangeGroups(items);

        let exchangeGroup = useMembership.current && userMembership !== null
            ? items.find(x => x.data?.id === userMembership.exchangeGroupId)
            : undefined;

        if (!exchangeGroup && items.length > 0){
            exchangeGroup = items[0];
        }

        setSelectedExchangeGroup(exchangeGroup);

        if (useMembership.current && userMembership !== null) {
            const language = languages.find(x => x.data?.id === userMembership.languageId);

            if (language) {
                setSelectedLanguage(language);
            }
        }

        useMembership.current = false;
    }, [languages, userMembership]);

    const selectExchangeGroup = useCallback((exchangeGroup?: SelectItemModel<ExchangeGroupModel>) => {
        setSelectedExchangeGroup(exchangeGroup);

        let maxLeasingAmount = exchangeGroup
            ? maxLeasingAmounts.find(x => x.value === exchangeGroup.data?.maxLeasingAmount)
            : undefined;

        if (!maxLeasingAmount) {
            maxLeasingAmount = maxLeasingAmounts[0];
        }

        setSelectedMaxLeasingAmount(maxLeasingAmount);

        let language = exchangeGroup
            ? languages.find(x => x.data?.id === exchangeGroup.data?.languageId)
            : undefined;

        if (!language) {
            language = languages[0];
        }

        setSelectedLanguage(language);
    }, [languages]);

    const joinOrCreate = useCallback(() => {
        if (!selectedCity || !selectedLanguage || !selectedMaxLeasingAmount) return;

        if (selectedExchangeGroup) {
            ApiService.joinToExchangeGroup(props.initData, selectedExchangeGroup.value)
                .then(() => {
                    Telegram.WebApp.close();
                },
                () => {
                    setStep("fail");
                });
        } else {
            ApiService.joinToNewExchangeGroup(props.initData, selectedCity.value, selectedLanguage.value, selectedMaxLeasingAmount.value)
                .then(() => {
                    Telegram.WebApp.close();
                },
                () => {
                    setStep("fail");
                });
        }
    }, [props.initData, selectedCity, selectedLanguage, selectedMaxLeasingAmount, selectedExchangeGroup]);

    // on load
    useEffectUnsafe(() => {
        Telegram.WebApp.expand();

        loadLanguages();
        loadCountries();
    }, [loadLanguages, loadCountries]);

    // on select country
    useEffect(() => {
        loadCities();
    }, [selectedCountry, loadCities]);

    // on select city
    useEffect(() => {
        if (!selectedCity) {
            setExchangeGroups([]);
            return;
        }

        ApiService.loadExchangeGroups(props.initData, selectedCity.value)
            .then(exchangeGroups => {
                fillExchangeGroups(Utils.toSelectItems(exchangeGroups));

                Telegram.WebApp.MainButton.setParams({
                    text: exchangeGroups.length > 0 ? t( "select_group.join") : t("select_group.create"),
                    is_active: true,
                    is_visible: true
                })
                .show();
            },
            () => {});
    }, [props.initData, t, selectedCity, fillExchangeGroups]);

    // validation
    useEffect(() => {
        setFormIsValid(!!selectedCountry && !!selectedCity);
    }, [selectedCountry, selectedCity]);

    return (
        <>
            <div className="select-group-dialog">
                <div className={"select-group-container " + (step === "select" ? "" : "hidden")}>
                    <p>{t("select_group.title")}</p>
                    <table className="form">
                        <tbody>
                            <tr>
                                <th>{t("select_group.country")}</th>
                                <td>
                                    <Select isSearchable={false}
                                            value={selectedCountry}
                                            onChange={(option) => setSelectedCountry(option || undefined)}
                                            options={countries}
                                            classNamePrefix="select-container"
                                            getOptionLabel={(props: any) => {
                                                const { data, label } = props;
                                                return (
                                                    <div className="country-item">
                                                        { data.flagImage && <img src={"data:image/png;base64," + data.flagImage} alt=""></img> }
                                                        <span>{label}</span>
                                                    </div>
                                                ) as unknown as string;
                                            }} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t("select_group.city")}</th>
                                <td>
                                    <Select isSearchable={false}
                                            value={selectedCity}
                                            onChange={(option) => setSelectedCity(option || undefined)}
                                            options={cities}
                                            classNamePrefix="select-container" />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="text-right small">
                                        <Trans i18nKey="select_group.city_not_exists">
                                            Вашего города нет в списке? <a href={addCityUrl} target="_blank" rel="noreferrer">Напишите нам</a>
                                        </Trans>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2} className="leasing-block">
                                    <div>
                                        {t("select_group.max_books_in_leasing")}:
                                        <Select isSearchable={false}
                                                isDisabled={!!selectedExchangeGroup}
                                                value={selectedMaxLeasingAmount}
                                                onChange={(option) => option && setSelectedMaxLeasingAmount(option)}
                                                options={maxLeasingAmounts}
                                                classNamePrefix="select-container" />
                                        <img src={process.env.PUBLIC_URL + '/help.svg'} className="help-icon" alt=""
                                            data-tooltip-content={t("select_group.max_leasing_amounts_tooltip")}></img>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>{t("select_group.language")}</th>
                                <td>
                                    <div className="dflex">
                                        <Select isSearchable={false}
                                                isDisabled={!!selectedExchangeGroup}
                                                value={selectedLanguage}
                                                onChange={(option) => setSelectedLanguage(option || undefined)}
                                                options={languages}
                                                classNamePrefix="select-container" />
                                        <img src={process.env.PUBLIC_URL + '/help.svg'} className="help-icon" alt=""
                                            data-tooltip-content={t("select_group.languages_tooltip")}></img>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("select_group.exchange_group")}</th>
                                <td>
                                    <Select isSearchable={false}
                                            isDisabled={exchangeGroups.length === 0}
                                            value={selectedExchangeGroup}
                                            onChange={(option) => selectExchangeGroup(option || undefined)}
                                            options={exchangeGroups}
                                            classNamePrefix="select-container" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={"red fail-container " + (step === "fail" ? "visible" : "")}>
                    {t("select_group.join_group_failed")}
                </div>
            </div>
            <Tooltip anchorSelect=".help-icon" place="left" className="tooltip" />
            {step === "select" && <MainButton onClick={joinOrCreate}
                                              text={t("add_book.add_book")}
                                              color={getComputedStyle(document.body).getPropertyValue(formIsValid ? "--tg-theme-button-color" : "--tg-theme-secondary-bg-color")}
                                              textColor={getComputedStyle(document.body).getPropertyValue(formIsValid ? "--tg-theme-button-text-color" : "--tg-theme-text-color")} /> }
            {step === "fail" && <BackButton onClick={() => setStep("select")} /> }
        </>
    );
}

export default SelectGroupDialog;