import { FC, useEffect } from "react";

/**
 * The props type of {@link MainButton | `MainButton`}.
 */
export interface MainButtonProps {
    /**
     * Current button text
     * @defaultValue Set to `CONTINUE` by default
     */
    text?: string;
    /**
     * The button progress state indicator.
     * @defaultValue    Set to `false` by default
     */
    progress?: boolean;
    /**
     * The button disable state.
     * @defaultValue Set to `false` y defaults
     */
    disable?: boolean;
    /** The button press event handler */
    onClick?: () => void;
    /**
     * Current button color.
     * @defaultValue Set to themeParams.button_color by default
     */
    color?: string;
    /**
     * Current button text color
     * @defaultValue Set to themeParams.button_text_color by default
     */
    textColor?: string;
}

/**
 * Renders a {@link telegram!MainButton} component in React app as {@link react!Component}
 *
 * ```tsx
 * import MainButton from "../components/MainButton";
 *
 * <MainButton
 *         text="CLICK ME"
 *         onClick={() => console.log('Hello, I am button!')}
 * />
 * ```
 * @returns Component always returns `null`. Not renders any elements
 * @group React Components
 */
const MainButton: FC<MainButtonProps> = ({
    text = 'CONTINUE',
    progress = false,
    disable = false,
    color,
    textColor,
    onClick,
}): null => {
    useEffect(() => {
        Telegram.WebApp.MainButton.setParams({
            color: color || Telegram.WebApp.themeParams.button_color || '#fff',
        });
    }, [color]);

    useEffect(() => {
        Telegram.WebApp.MainButton.setParams({
            text_color: textColor || Telegram.WebApp.themeParams.button_text_color || '#000',
        });
    }, [textColor]);

    useEffect(() => {
        Telegram.WebApp.MainButton.setText(text);
    }, [text]);

    useEffect(() => {
        if (Telegram.WebApp.MainButton.isActive && disable) {
            Telegram.WebApp.MainButton.disable();
        } else if (!Telegram.WebApp.MainButton.isActive && !disable) {
            Telegram.WebApp.MainButton.enable();
        }
    }, [disable]);

    useEffect(() => {
        if (!Telegram.WebApp.MainButton.isProgressVisible && progress) {
            Telegram.WebApp.MainButton.showProgress(false);
        } else if (Telegram.WebApp.MainButton.isProgressVisible && !progress) {
            Telegram.WebApp.MainButton.hideProgress();
        }
    }, [progress]);

    useEffect(() => {
        if (!onClick) {
            return;
        }

        Telegram.WebApp.MainButton.onClick(onClick);
        return () => {
            (Telegram.WebApp.MainButton as any).offClick(onClick);
        };
    }, [onClick]);

    useEffect(() => {
        Telegram.WebApp.MainButton.show();
        return () => {
            Telegram.WebApp.MainButton.hide();
        };
    }, []);

    return null;
};

export default MainButton;