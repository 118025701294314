import "./Photo.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import useEffectUnsafe from "../extensions/UseEffectUnsafe";
import { t } from "i18next";
import MainButton from "./MainButton";
import useScreenOrientation from "../extensions/UseScreenOrientation";

interface IProps {
    onTakePhoto: (imageData: string | null) => void;
}

const Photo = (props: IProps) => {
    const screenOrientation = useScreenOrientation();

    const videoConstraints = {
        width: 640,
        height: 360,
        facingMode: "environment"
    };

    const webcamRef = useRef<Webcam>(null);
    const [step, setStep] = useState<"photo" | "capture">("photo");
    const [hasCamera, setHasCamera] = useState<boolean>(false);

    const takePhoto = useCallback(() => {
        if (webcamRef.current) {
            setStep("capture");
        }
    }, [webcamRef]);

    // on load
    useEffectUnsafe(() => {
        navigator.mediaDevices.enumerateDevices().then((mediaDevices: MediaDeviceInfo[]) => {
            const hasCamera = mediaDevices.some(x => x.kind === "videoinput");
            setHasCamera(hasCamera);
        });
    }, []);

    useEffect(() => {
        if (step === "capture") {
            const imageSrc = webcamRef.current!.getScreenshot({
                width: screenOrientation === "portrait-primary" ? 360 : 640,
                height: screenOrientation === "portrait-primary" ? 640 : 360
            });

            (webcamRef.current!.video!.srcObject as MediaStream).getVideoTracks().forEach(x => x.stop());

            if (imageSrc === null) return;

            props.onTakePhoto(imageSrc);
        }
    }, [step, webcamRef, screenOrientation, props]);

    return (
        <>
            <div className={"webcam-container" + (step === "photo" ? "" : " hidden")}>
                { hasCamera && <Webcam ref={webcamRef}
                                       audio={false}
                                       width="100%"
                                       height="100%"
                                       screenshotFormat="image/png"
                                       videoConstraints={videoConstraints}
                                       screenshotQuality={1}
                                       imageSmoothing={false}
                                       minScreenshotWidth={screenOrientation === "portrait-primary" ? 360 : 640}
                                       minScreenshotHeight={screenOrientation === "portrait-primary" ? 640 : 360} /> }
                { !hasCamera && t("photo.camera_not_found") }
            </div>
            <MainButton onClick={takePhoto}
                        text={t("photo.take_photo")}
                        disable={!hasCamera || step === "capture"}
                        color={getComputedStyle(document.body).getPropertyValue(hasCamera ? "--tg-theme-button-color" : "--tg-theme-secondary-bg-color")}
                        textColor={getComputedStyle(document.body).getPropertyValue(hasCamera ? "--tg-theme-button-text-color" : "--tg-theme-text-color")} />
            {step === "capture" && <div className="processing visible">
                <div className="placeholder">
                    <div className="spinner"></div>
                </div>
            </div>}
        </>
    );
}

export default Photo;