import "./FileUpload.scss";
import Dropzone from "react-dropzone-uploader";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { useTranslation } from "react-i18next";

interface IProps {
    onFileSelect: (file: File) => void;
    disabled?: boolean;
}

const FileUpload = (props: IProps) => {
    const isMobile = !["tdesktop", "webz"].includes((Telegram.WebApp as any).platform);

    const { t } = useTranslation();

    const handleChangeStatus = ({ file } : IFileWithMeta, status: StatusValue) => {
        if (status === "done") {
            props.onFileSelect(file);
        }
    };

    return (
        <div className="file-upload">
            <Dropzone onChangeStatus={handleChangeStatus}
                      PreviewComponent={() => null}
                      SubmitButtonComponent={() => null}
                      accept="image/*"
                      maxFiles={1}
                      multiple={false}
                      disabled={props.disabled}
                      inputContent={isMobile ? t("file_upload.select_file") : t("file_upload.drop_or_select_file")}/>
        </div>
    )
}

export default FileUpload;