import "./AddBookDialog.scss";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import useEffectUnsafe from "../extensions/UseEffectUnsafe";
import Photo from "../components/Photo";
import FileUpload from "../components/FileUpload";
import { Utils } from "../extensions/Utils";
import { ApiService } from "../services/ApiService";
import { ItemModel, SelectItemModel, LanguageModel, UserMembershipDataModel } from "../models/Models";
import MainButton from "../components/MainButton";
import BackButton from "../components/BackButton";
import Crop from "../components/Crop";
import { useSearchParams } from "react-router-dom";

interface IProps {
    initData: string;
}

const maxLeaseDurations = Array.from(new Array(30), (_, i) => {
    return {
        value: i + 1,
        label: (i + 1).toString(),
        data: i + 1
    } as SelectItemModel<number>;
});

const AddBookDialog = (props: IProps) => {
    const isIOS = (Telegram.WebApp as any).platform === "ios";

    const [searchParams] = useSearchParams();
    const [step, setStep] = useState<"add" | "photo" | "crop" | "fail">("add");
    const [name, setName] = useState<string>("");
    const [image, setImage] = useState<string>();
    const [genres, setGenres] = useState<SelectItemModel<ItemModel>[]>([]);
    const [selectedGenres, setSelectedGenres] = useState<SelectItemModel<ItemModel>[]>([]);
    const [author, setAuthor] = useState<string>("");
    const [languages, setLanguages] = useState<SelectItemModel<LanguageModel>[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<SelectItemModel<LanguageModel>>();
    const [selectedMaxLeaseDuration, setSelectedMaxLeaseDuration] = useState<SelectItemModel<number>>(maxLeaseDurations[maxLeaseDurations.length - 1]);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [userMembership, setUserMembership] = useState<UserMembershipDataModel | null>(null);
    const [addGenreUrl, setAddGenreUrl] = useState<string>("");

    const { t } = useTranslation();

    const loadGenres = useCallback(() => {
        ApiService.loadGenres(props.initData).then(genres =>{
            setGenres(Utils.toSelectItems(genres));
        },
        () => {})
    }, [props.initData]);

    const loadUserMembership = useCallback(() => {
        ApiService.getUserMembership(props.initData)
            .then(userMembership => {
                setAddGenreUrl(userMembership.addGenreUrl);
                setUserMembership(userMembership.data);
            },
            () => {});
    }, [props.initData]);

    const loadLanguages = useCallback(() => {
        ApiService.loadLanguages(props.initData)
            .then(languages => {
                const items = Utils.toSelectItems(languages);

                setLanguages(items);

                if (items.length > 0) {
                    const code = Telegram.WebApp.initDataUnsafe.user?.language_code;
                    const language = (code ? items.find(x => x.data?.code === code) : undefined) ?? items[0];
                    setSelectedLanguage(language);
                } else {
                    setSelectedLanguage(undefined);
                }
            },
            () => {});
    }, [props.initData]);

    const addBook = useCallback(() => {
        if (Utils.isNullOrEmpty(name) || selectedGenres.length === 0 || Utils.isNullOrEmpty(author) || !selectedLanguage) {
            return;
        }

        const bookrequestid = searchParams.get("bookrequestid");
        const bookRequestId = Utils.isNullOrEmpty(bookrequestid) ? undefined : Number.parseInt(bookrequestid!, 10);

        ApiService.addBook(props.initData,
                           name,
                           selectedGenres.map(x => x.data?.id as number),
                           author,
                           selectedLanguage.data?.id as number,
                           selectedMaxLeaseDuration.data as number,
                           image,
                           bookRequestId)
            .then(() => {
                Telegram.WebApp.close();
            },
            () => {
                setStep("fail");
            });
    }, [props.initData, name, selectedGenres, author, selectedLanguage, selectedMaxLeaseDuration, image, searchParams]);

    // on load
    useEffectUnsafe(() => {
        Telegram.WebApp.expand();

        loadGenres();
        loadLanguages();
        loadUserMembership();
    }, []);

    // validation
    useEffect(() => {
        if (step !== "photo") {
            const isValid = !Utils.isNullOrEmpty(name) && selectedGenres.length > 0 && !Utils.isNullOrEmpty(author) && !!selectedLanguage;
            setFormIsValid(isValid);
        }
    }, [step, name, selectedGenres, author, selectedLanguage]);

    const takePhoto = useCallback(() => {
        setStep("photo");
    }, []);

    const onTakePhoto = useCallback((imageData: string | null) => {
        if (imageData !== null) {
            setImage(imageData);
        }
        setStep("crop");
    }, [setStep]);

    const onCrop = useCallback((imageData: string | null) => {
        if (imageData !== null) {
            setImage(imageData);
        }
        setStep("add");
    }, [setStep]);

    const onFileSelected = useCallback((file: File) => {
        Utils.getBase64(file).then(data => {
            setImage(data);
            setStep("crop");
        });
    }, []);

    return (
        <>
            <div className="add-book-dialog">
                <div className={"add-container " + (step === "add" ? "" : "hidden")}>
                    <div>
                        <table className="form">
                            <tbody>
                                <tr>
                                    <th>{t("add_book.title")}</th>
                                    <td>
                                        <input type="text" value={name} onChange={e => setName(e.target.value)} title={t("add_book.title")}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("add_book.genre")}</th>
                                    <td>
                                        <Select isSearchable={false}
                                                value={selectedGenres}
                                                closeMenuOnSelect={true}
                                                isMulti
                                                onChange={options => setSelectedGenres(options.map(x => x))}
                                                options={genres}
                                                classNamePrefix="select-container" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="text-right small">
                                            <Trans i18nKey="add_book.genre_not_exists">
                                                Жанра нет в списке? <a href={addGenreUrl} target="_blank" rel="noreferrer">Напишите нам</a>
                                            </Trans>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("add_book.author")}</th>
                                    <td>
                                        <input type="text" value={author} onChange={e => setAuthor(e.target.value)} title={t("add_book.author")}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("add_book.language")}</th>
                                    <td>
                                        <Select isSearchable={false}
                                                value={selectedLanguage}
                                                onChange={option => setSelectedLanguage(option || undefined)}
                                                options={languages}
                                                classNamePrefix="select-container" />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2} className="leasing-block">
                                        <div>
                                            {t("add_book.max_leasing_duration")}:
                                            <div>
                                                <Select isSearchable={false}
                                                        value={selectedMaxLeaseDuration}
                                                        onChange={option => option && setSelectedMaxLeaseDuration(option)}
                                                        options={maxLeaseDurations}
                                                        classNamePrefix="select-container" />{t("add_book.days")}
                                                <img src={process.env.PUBLIC_URL + '/help.svg'}
                                                     className="help-icon"
                                                     alt=""
                                                     data-tooltip-content={t("add_book.max_leasing_duration_tooltip")}></img>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>

                        <table className="form">
                            <tbody>
                                <tr>
                                    <th className="photo-block">
                                        {t("add_book.upload_photo")}:
                                        <div className="small">
                                            <Trans i18nKey="add_book.photo_description">
                                                Фотография поможет другим найти Вашу книгу в <a href={userMembership?.catalogUrl} target="_blank" rel="noreferrer">каталоге</a>
                                            </Trans>
                                        </div>
                                    </th>
                                    <td>
                                        {!image && <div className="file-upload-container">
                                            {!isIOS && <button type="button" onClick={() => takePhoto()}>{t("add_book.take_photo")}</button>}
                                            <FileUpload onFileSelect={file => onFileSelected(file)}
                                                        disabled={step !== "add"} />
                                        </div> }
                                        {image && <div className="file-preview-container">
                                            <img className="image-preview" src={image} alt="" />
                                            <button type="button" onClick={() => setImage(undefined)}>{t("add_book.remove_photo")}</button>
                                        </div> }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={"photo-container " + (step === "photo" ? "visible" : "")}>
                    {step === "photo" && <Photo onTakePhoto={imageData => onTakePhoto(imageData)} /> }
                </div>

                <div className={"crop-container " + (step === "crop" ? "visible" : "")}>
                    {step === "crop" && <Crop imageSrc={image} onCrop={imageData => onCrop(imageData)} /> }
                </div>

                <div className={"red fail-container " + (step === "fail" ? "visible" : "")}>
                    {t("add_book.add_book_failed")}
                </div>
            </div>
            <Tooltip anchorSelect=".help-icon" place="left" className="tooltip" />
            {step === "add" &&
                <MainButton onClick={addBook}
                            text={t("add_book.add_book")}
                            color={getComputedStyle(document.body).getPropertyValue(formIsValid ? "--tg-theme-button-color" : "--tg-theme-secondary-bg-color")}
                            textColor={getComputedStyle(document.body).getPropertyValue(formIsValid ? "--tg-theme-button-text-color" : "--tg-theme-text-color")} /> }
            {step !== "add" && <BackButton onClick={() => setStep("add")} /> }
        </>
    );
}
export default AddBookDialog;
