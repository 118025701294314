import { SelectItemModel } from "../models/Models";

export class Utils {
    public static getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader!.result!.toString());
            };
            reader.onerror = (error) => reject(error);
        });
    };

    public static toSelectItems = <T extends {id: number, name: string}>(items: T[]) => {
        return items.map(x => {
            return {
                value: x.id,
                label: x.name,
                data: x
            } as SelectItemModel<T>;
        });
    };

    public static isNullOrEmpty = (value?: string | null) => {
        return value === null || value === undefined || value === "";
    };
}