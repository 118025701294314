import { usePromiseTracker } from "react-promise-tracker";

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();

    if (!promiseInProgress) {
        return null;
    }

    return (
        <div className="processing visible">
            <div className="placeholder">
                <div className="spinner"></div>
            </div>
        </div>
    );
}

export default LoadingIndicator;