import { FC, useEffect } from 'react';

/**
 * Renders a {@link telegram!BackButton} component in React app as {@link react!Component}
 *
 * ```tsx
 * import BackButton from "..components/BackButton";
 *
 * <BackButton onClick={() => console.log('Hello, I am back button!')}
 * />
 * ```
 * @returns Component always returns `null`. Not renders any elements
 * @group React Components
 */
const BackButton: FC<{onClick: () => void}> = ({ onClick }) => {
    useEffect(() => {
        (Telegram.WebApp as any).BackButton.show();
        return () => {
            (Telegram.WebApp as any).BackButton.hide();
        };
    }, []);

    useEffect(() => {
        if (!onClick) {
            return;
        }

        (Telegram.WebApp as any).BackButton.onClick(onClick);
        return () => {
            (Telegram.WebApp as any).BackButton.offClick(onClick);
        };
    }, [onClick]);

    return null;
};

export default BackButton;
