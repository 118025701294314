import "./App.scss";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ApiService } from "./services/ApiService";

const App = () => {
    const { i18n } = useTranslation();

    const [auth, setAuth] = useState<"loading" | "success" | "fail">("loading");
    const { t } = useTranslation();

    useEffect(() => {

        const checkAuth = () => {
            if (["", null].includes(Telegram.WebApp.initData)) {
                setAuth("fail");
                return;
            }

            ApiService
                .auth(Telegram.WebApp.initData)
                .then(success => setAuth(success ? "success" : "fail"))
                .catch(() => setAuth("fail"));
        };

        Telegram.WebApp.ready();

        checkAuth();
    }, []);

    useEffect(() => {
        i18n.changeLanguage(Telegram.WebApp.initDataUnsafe.user?.language_code ?? "en");
    }, [i18n]);

    switch (auth) {
        case "fail":
            Telegram.WebApp.MainButton.hide();
            return (
                <div className="app">
                        <header className="app-header">
                                <div className="auth-error">
                                        {t("app.auth_error")}
                                </div>
                        </header>
                </div>
            );
        case "success":
            return (
                <Routes>
                        {AppRoutes(Telegram.WebApp.initData).map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                        })}
                </Routes>
            );
        default:
            return <></>;
    }
}

export default App;
