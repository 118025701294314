import AddBookDialog from "./dialogs/AddBookDialog";
import SelectGroupDialog from "./dialogs/SelectGroupDialog";

const AppRoutes = (initData: string) => [
    {
        index: true,
        element: <div>Books Crossing</div>
    },
    {
        path: "/selectgroup",
        element: <SelectGroupDialog initData={initData} />
    },
    {
        path: "/addbook",
        element: <AddBookDialog initData={initData} />
    }
];

export default AppRoutes;
