import "./Crop.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop as ImageCrop } from "react-image-crop";
import { t } from "i18next";
import MainButton from "./MainButton";

interface IProps {
    onCrop: (imageData: string | null) => void;
    imageSrc?: string;
}

const Crop = (props: IProps) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const [processing, setProcessing] = useState<boolean>(false);
    const [crop, setCrop] = useState<ImageCrop>({
        unit: "%",
        x: 10,
        y: 10,
        width: 80,
        height: 80
    });

    const cropImage = useCallback(() => {
        if (imageRef.current === null) return;

        const canvas = document.createElement("canvas");
        const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
        const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

        const cropWidth = crop.unit === "%" ? imageRef.current.width * crop.width / 100 : crop.width;
        const cropHeight = crop.unit === "%" ? imageRef.current.height * crop.height / 100 : crop.height;

        const ctx = canvas.getContext("2d");

        canvas.width = cropWidth * scaleX;
        canvas.height = cropHeight * scaleY;

        ctx!.imageSmoothingQuality = "high";

        ctx!.drawImage(
            imageRef.current,
            crop.x * scaleX,
            crop.y * scaleY,
            cropWidth * scaleX,
            cropHeight * scaleY,
            0,
            0,
            cropWidth * scaleX,
            cropHeight * scaleY,
        );

        const base64Image = canvas.toDataURL("image/png");
        return base64Image;
    }, [imageRef, crop]);

    useEffect(() => {
        if (processing) {
            const croppedImage = cropImage();
            props.onCrop(croppedImage || null);
        }
    }, [processing, cropImage, props]);

    return (
        <>
            <div className={"crop-image-container"}>
                <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                    <img src={props.imageSrc} alt="" ref={imageRef} />
                </ReactCrop>
            </div>
            <MainButton onClick={() => setProcessing(true)}
                        text={t("photo.done")}
                        color={getComputedStyle(document.body).getPropertyValue("--tg-theme-button-color")}
                        textColor={getComputedStyle(document.body).getPropertyValue("--tg-theme-button-text-color")} />
            {processing && <div className="processing visible">
                <div className="placeholder">
                    <div className="spinner"></div>
                </div>
            </div>}
        </>
    );
}

export default Crop;