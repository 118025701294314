import { trackPromise } from "react-promise-tracker";
import { CountryModel, ExchangeGroupModel, ItemModel, LanguageModel, UserMembershipModel } from "../models/Models";

export class ApiService {
    static async auth(authInfo: string): Promise<boolean> {
        const result = await trackPromise(fetch("/api/webapps/auth", {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return result.status === 200;
    }

    static async loadLanguages(authInfo: string): Promise<LanguageModel[]> {
        const result = await trackPromise(fetch(`/api/webapps/languages`, {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return await result.json();
    }

    static async loadCountries(authInfo: string): Promise<CountryModel[]> {
        const result = await trackPromise(fetch("/api/webapps/countries", {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return await result.json();
    }

    static async loadCities(authInfo: string, countryId: number): Promise<ItemModel[]> {
        const result = await trackPromise(fetch(`/api/webapps/countries/${countryId}/cities`, {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return await result.json();
    }

    static async loadGenres(authInfo: string): Promise<ItemModel[]> {
        const result = await trackPromise(fetch("/api/webapps/genres", {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return await result.json();
    }

    static async loadExchangeGroups(authInfo: string, cityId: number): Promise<ExchangeGroupModel[]> {
        const result = await trackPromise(fetch(`/api/webapps/cities/${cityId}/exchangegroups`, {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return await result.json();
    }

    static async getUserMembership(authInfo: string): Promise<UserMembershipModel> {
        const result = await trackPromise(fetch("/api/webapps/users/membership", {
            method: "GET",
            headers: this.getHeaders(authInfo)
        }));
        return await result.json();
    }

    static async joinToNewExchangeGroup(authInfo: string, cityId: number, languageId: number, maxLeasingAmount: number): Promise<any> {
        const result = await trackPromise(fetch('/api/webapps/users/jointonewexchangegroup', {
            method: "POST",
            headers: this.getHeaders(authInfo),
            body: JSON.stringify({
                cityId,
                languageId,
                maxLeasingAmount
            })
        }));
        return result.text();
    }

    static async joinToExchangeGroup(authInfo: string, exchangeGroupId: number): Promise<any> {
        const result = await trackPromise(fetch('/api/webapps/users/jointoexchangegroup', {
            method: "POST",
            headers: this.getHeaders(authInfo),
            body: JSON.stringify(exchangeGroupId)
        }));
        return await result.text();
    }

    static async addBook(authInfo: string,
                         name: string,
                         genresIds: number[],
                         author: string,
                         languageId: number,
                         maxLeaseDuration: number,
                         fileData?: string,
                         bookRequestId?: number): Promise<any> {
        const result = await trackPromise(fetch('/api/webapps/books', {
            method: "POST",
            headers: this.getHeaders(authInfo),
            body: JSON.stringify({
                name,
                genresIds,
                author,
                languageId,
                maxLeaseDuration,
                image: fileData,
                bookRequestId: bookRequestId
            })
        }));
        return await result.text();
    }

    private static getHeaders(authInfo: string): HeadersInit {
        return {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": authInfo
        };
    }
}
