import "./index.scss";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import translation_ru from "./i18n/translations/ru.json";
import translation_en from "./i18n/translations/en.json";
import translation_es from "./i18n/translations/es.json";
import LoadingIndicator from "./components/LoadingIndicator";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

i18next.use(initReactI18next).init({
    interpolation: {
        escapeValue: false
    },
    returnNull: false,
    resources: {
        en: {
            translation: translation_en
        },
        ru: {
            translation: translation_ru
        },
        es: {
            translation: translation_es
        }
    },
    lng: "en",
    fallbackLng: "en"
});

root.render(
    <React.StrictMode>
            <BrowserRouter basename={baseUrl}>
                <I18nextProvider i18n={i18next}>
                    <App />
                </I18nextProvider>
            </BrowserRouter>
        <LoadingIndicator/>
    </React.StrictMode>
);
